<template>
    <div>
        <el-row>
            <el-col :span="24" type="flex" class="main">
                <el-row :span="12" class="content">
                    <el-col :span="7" :offset="2">
                        <el-image
                                style="width: 160px; height: 80px"
                                :src="logoUrl"
                                fit="contain"></el-image>
                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                                 class="demo-ruleForm">
                            <el-form-item label="用户名/邮箱" prop="userName">
                                <el-input type="text" placeholder="用户名/邮箱" v-model="ruleForm.userName"
                                          autocomplete="off"
                                          @blur="queryPhoneByName"/>
                            </el-form-item>
                            <el-form-item v-if="enableLoginVerificationCode" label="登录手机号" prop="personalPhone">
                                <el-input type="text" placeholder="请输入登录手机号" disabled
                                          v-model="ruleForm.personalPhone"
                                          autocomplete="off"/>
                            </el-form-item>
                            <el-form-item v-if="!enableLoginVerificationCode" label="密码" prop="password">
                                <el-input type="password" placeholder="密码" v-model="ruleForm.password"
                                          autocomplete="off"/>
                            </el-form-item>
                            <el-form-item v-if="enableLoginVerificationCode" label="验证码" prop="code">

                                <el-input placeholder="请输入验证码" :maxlength="10" onkeyup="value=value.replace(/\D/g,'')"
                                          v-model="ruleForm.code">
                                    <template #suffix>
                                        <el-button v-if="!isSendCode" @click="sendLoginCode" :disabled="buttonDisabled"
                                                   type="primary" size="small">发送验证码
                                        </el-button>
                                        <el-button v-else type="primary" size="small" disabled>{{ sendCodeTime }}秒后重新发送
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button style="width: 100%" class="confirm" type="primary"
                                           @click="submitForm('ruleForm')">登录
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <el-dialog title="您的账号已被锁定, 请修改密码" :visible.sync="dialogUpdateForm" :close-on-click-modal="false"
                   @open="openUpdateDialog" width="800px">
            <el-form :model="updateForm" label-width="120px" :rules="updateRules" ref="updateForm">
                <el-form-item label="用户名/邮箱：" prop="userName">
                    <el-input v-model="updateForm.userName" disabled autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录手机号：" prop="mobile">
                    <el-input v-model="updateForm.mobile" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="验证码：" prop="code">
                    <el-input placeholder="请输入验证码" :maxlength="6" onkeyup="value=value.replace(/\D/g,'')"
                              v-model="updateForm.code">
                        <template #suffix>
                            <el-button v-if="!isSendCode" @click="handleCheckSendCode" :disabled="buttonDisabled"
                                       type="primary" size="small">发送验证码
                            </el-button>
                            <el-button v-else type="primary" size="small" disabled>{{ sendCodeTime }}秒后重新发送
                            </el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="密码：" prop="password">
                    <el-input v-model="updateForm.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码：" prop="checkPassword">
                    <el-input v-model="updateForm.checkPassword" autocomplete="off"></el-input>
                    <p style="font-size: 10px;color: rgb(81 81 81);padding: 0;margin: 0;line-height: 12px;width: 70%;margin-top: 10px">
                        密码长度不少于6位，需组合大小英文字母、数字、特殊符号中的至少2种</p>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogUpdateForm = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdateForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import '../../main.js'
import {custom, login} from "@/utils/api";
import {getToken, removeInformation, removeToken} from '@/utils/auth'
import * as accountApi from "@/api/system/account";

function isValidPhoneNumber(phoneNumber) {
    // 正则表达式匹配中国的手机号，格式为：1[3-9]\d{9}
    // 1开头，第二位是3-9之间的数字，后面跟着9位数字
    const regex = /^1[3-9]\d{9}$/;
    return regex.test(phoneNumber);
}

export default {
    name: "login",
    data() {
        return {
            ruleForm: {
                userName: '',
                personalPhone: '',
                password: '',
                code: '',
                keepLogin: 1
            },
            rules: {
                userName: [{required: true, message: '请输入用户名/邮箱', trigger: 'blur'}],
                personalPhone: [{required: true, message: '请输入登录手机号', trigger: 'blur'}],
                code: [{required: true, message: '请输入短信验证码', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}]
            },
            logoUrl: '',
            information: '',
            dialogUpdateForm: false,
            updateRules: {
                mobile: [
                    {required: true, message: '请输入手机号', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入新密码', trigger: 'blur'}
                ],
                checkPassword: [
                    {required: true, message: '请确认新密码', trigger: 'blur'}
                ]
            },
            updateForm: {
                userName: '',
                mobile: '',
                code: '',
                password: '',
                checkPassword: ''
            },
            // 60s倒计时
            sendCodeTime: 60,
            sendCodeInterval: null,
            isSendCode: false,
            enableLoginVerificationCode: false,
            keyboardInput: []
        }
    },
    created() {
        this.initConfig()
        let t = this;
        let inputValArr = '38,38,40,40,37,39,37,39,66,65,66,65';
        document.onkeydown = function (e) {
            let keyCode = e.keyCode;
            //enter的ASCII码是13
            if (keyCode === 13) {
                t.submitForm('ruleForm');//t.登录方法
            }else {
                t.keyboardInput.push(keyCode)
            }
            if(t.keyboardInput.join(',').indexOf(inputValArr) > -1) {
                t.$message.success('成功切换登录方式')
                t.keyboardInput = []
                t.enableLoginVerificationCode = !t.enableLoginVerificationCode
            }
        }
    },
    computed: {
        buttonDisabled() {
            if (this.updateForm.mobile) {
                return !isValidPhoneNumber(this.updateForm.mobile);
            }else if (this.ruleForm.personalPhone) {
                return !isValidPhoneNumber(this.ruleForm.personalPhone);
            }else {
                return true
            }
        },
    },
    mounted() {
    },
    methods: {
        sendLoginCode() {
            // 发送验证码
            accountApi.sendCode(this.ruleForm.personalPhone).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.createSendCode()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        queryPhoneByName() {
            if (!this.enableLoginVerificationCode) {
                return;
            }
            this.ruleForm.personalPhone = ''
            accountApi.queryPhoneByName(this.ruleForm.userName).then(res => {
                if (res.success) {
                    if (res.data) {
                        this.ruleForm.personalPhone = res.data;

                    } else {
                        this.$message.error("请检查用户名是否输入正确");
                    }
                } else {
                    this.$message.error("请检查用户名是否输入正确");
                }
            })
        },
        openUpdateDialog() {
            this.updateForm.mobile = ''
            this.updateForm.code = ''
            this.updateForm.password = ''
            this.updateForm.checkPassword = ''
        },
        dialogClose() {
            clearInterval(this.sendCodeInterval)
        },
        // 创建发送验证码倒计时
        createSendCode() {
            this.isSendCode = true
            this.sendCodeInterval = setInterval(() => {
                this.sendCodeTime--
                if (this.sendCodeTime <= 0) {
                    clearInterval(this.sendCodeInterval)
                    this.sendCodeTime = 60
                    this.isSendCode = false
                }
            }, 1000)
        },
        /**
         * 修改密码
         */
        submitUpdateForm() {
            if (this.updateForm.password !== this.updateForm.checkPassword) {
                this.$message.error("两次密码输入不一致")
                return;
            }
            accountApi.updatePwdByUser(this.updateForm).then(res => {
                if (res.success) {
                    this.dialogUpdateForm = false;
                    this.$message.success("密码更新成功,请登录")
                    setTimeout(function () {
                        removeToken()
                        removeInformation()
                    }, 1000)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleCheckSendCode() {
            accountApi.checkSendCode(this.updateForm.userName, this.updateForm.mobile).then(resp => {
                if (resp.success) {
                    this.sendSms();
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        sendSms() {
            // 发送验证码
            accountApi.sendCode(this.updateForm.mobile).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.createSendCode()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    login(this.ruleForm).then(res => {
                        // console.log(res)
                        // console.log(this.$route.query.redirect)
                        if (res.success) {
                            this.$store.commit("SET_TOKEN", getToken());
                            this.information = res.data.loginUser;
                            this.$store.commit("INFO_RMATION", this.information);
                            // console.log(this.$store.state.information)
                            localStorage.setItem('headerTitle', "学习顾问页面")
                            // let pathUrl = this.$route.query.redirect ? this.$route.query.redirect : '/';
                            // let pathUrl = 'welcome'
                            // console.log(pathUrl)

                            // console.log("*************", res.data.permission)
                            localStorage.setItem("permission", res.data.permission);
                            localStorage.setItem("servicesId", res.data.loginUser.servicesId);
                            const params = {
                                binding: true
                            }
                            if (!res.data.loginUser.personalPhone) {
                                params.binding = false
                            }
                            // this.$router.push("/welcome", params)
                            this.$router.push({
                                path: "/welcome",
                                query: params
                            })
                        } else if (res.code === 520) {
                            this.dialogUpdateForm = true
                            this.updateForm.userName = this.ruleForm.userName
                            this.$message.warning(res.msg)
                        } else {
                            this.$message.error(res.msg);
                        }

                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        tijiao() {
            let self = this;
            let pathUrl = self.$route.query.redirect ? self.$route.query.redirect : '/';
            this.$router.replace(pathUrl);

        },
        initConfig: function () {
            custom().then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.logoUrl = resEntity.logoPath
                    document.title = resEntity.netName ? resEntity.netName : "CRM"
                    let linkObject = document.createElement('link');
                    linkObject.rel = "shortcut icon";
                    linkObject.href = resEntity.smallLogoPath ? resEntity.smallLogoPath : "";
                    document.getElementsByTagName("head")[0].appendChild(linkObject);
                    this.enableLoginVerificationCode = res.data.enableLoginVerificationCode
                }
            })

        }
    }

}
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    height: 100vh;
    background: url("../../assets/images/background.png") 50% 0 /cover;

    .content {
        text-align: center;
        top: 50%;
        margin-top: -135px;
    }
}
</style>
